@import 'utils.scss';
@import 'breakpoints.scss';

.container.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--desktop-padding, '40px 0');
  overflow: hidden;
  background-color: var(--color-background-primary);
  color: currentcolor;
  gap: 30px;

  @include to(1) {
    padding: var(--mobile-padding);
  }
}

.backgroundImage {
  position: absolute;
  top: 0;
  pointer-events: none;

  @include to(1) {
    display: none;
  }
}

.mobileBackgroundImage {
  position: absolute;
  top: 0;
  pointer-events: none;

  @include from(2) {
    display: none;
  }
}

.title {
  max-width: 24ch;
  text-align: center;

  @include to(1) {
    max-width: 14ch;
    font-size: 38px;
  }
}

.stats {
  display: flex;
  align-items: center;
  gap: 100px;

  @include to(1) {
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }
}

.valueContainer {
  font-weight: 300;
}

.value.value {
  font-size: 100px;
  font-weight: 300;
  line-height: 1;

  @include to(1) {
    font-size: 65px;
  }
}

.description.description {
  font-size: 26px;
  text-transform: uppercase;

  @include to(1) {
    font-size: 16px;
  }
}
