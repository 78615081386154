@import 'colors.scss';

.passwordInputsContainer {
  width: 100%;
  max-width: 650px;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px $warning inset;
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.submit {
  width: 100%;
}

.divider {
  margin: 16px 0;
  text-align: center;
}

.oauthProvidersContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
